import './_vendor';
import vars from './_vars';
import './_functions';
import './_components';



// FIXED HEADER ========================================================================================================================================================

const header = document.querySelector('.header'),
      regBtn = document.querySelector('.header__registration'),
      headerNavigation = document.querySelector('.header__wrap'),
      navContainer = document.querySelector('.nav');

function scrollHeader() {
  if (document.documentElement.scrollTop > 1 || document.body.scrollTop > 1) {
      header.classList.add('scroll');
  } else {
    header.classList.remove('scroll');
  }
}

function transformRegisterHeaderBlock() {
  if (document.documentElement.clientWidth <= 992 || document.body.clientWidth <= 992) {
    navContainer.appendChild(regBtn);
  } else {
    headerNavigation.append(regBtn);
  }
}

window.addEventListener('resize', () => {
  transformRegisterHeaderBlock();
})

window.addEventListener('DOMContentLoaded', () => {
  transformRegisterHeaderBlock();
})

window.addEventListener('scroll', () => {
  scrollHeader();
})

// DROPDOWN MENU ========================================================================================================================================================


  const dropDownLink = document.querySelector('.header nav > ul > li:nth-child(4)');

    dropDownLink.addEventListener('click', () => {
    dropDownLink.classList.toggle('active');
  });


// ACTIVITY CLASS ========================================================================================================================================================

if (document.querySelectorAll('.nav ul a').length > 0) {
  const menuContainer = document.querySelectorAll('.nav ul a');

  menuContainer.forEach(function (el) {
    if (window.location.pathname.indexOf(el.getAttribute('href')) > -1) {
      el.classList.add('active');
    } else {
      el.classList.remove('active');
    }
  });
}

// GET YEAR ========================================================================================================================================================


if (document.querySelectorAll('.footer__copyright').length > 0) {

  let yearSpan = document.querySelector('.year');
  let date = new Date;
  let yearFull = date.getFullYear();

  yearSpan.innerHTML = yearFull;
}

// WOW SJ ========================================================================================================================================================

new WOW().init();


//Testimonials slider  ========================================================================================================================================================

const sliderTestimonials = document.querySelector('.swiper-testimonials');

const swiper = new Swiper(sliderTestimonials, {
  slidesPerView: 1,
  loop: true,
  speed: 800,
  autoplay: {
    delay: 5000,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    577: {
      slidesPerView: 2,
    },
    993: {
      slidesPerView: 3,
      centeredSlides: true,
    }
  },

  pagination: {
    el: '.swiper-pagination',
  },
});

// Select 2 ========================================================================================================================================================

$(document).ready(function() {
  $('.filters__item select, .contact__select select').select2();
});


if (document.querySelectorAll('.individual__booking').length > 0) {

    let inputs = document.querySelectorAll('.individual__booking input[type="radio"]');
    let labels = document.querySelectorAll('.individual__booking .form__label');

    inputs.forEach(el => {
      el.addEventListener('click', (e) => {
        labels.forEach(elem => {
          elem.style.border = '1px solid #dddddd';
        })
        e.target.parentElement.style.border = '1px solid var(--accent--color)';
      })
    })
}

// Tabs for contact page ========================================================================================================================================================

if (document.querySelectorAll('.contact').length > 0) {
  let tabs = document.querySelector('.tabs__header'),
    tabsItem = document.querySelectorAll('.tabs__item'),
    tabsInfo = document.querySelectorAll('.tabs__info');

    tabs.addEventListener('click', fTabs);

    function fTabs(event) {
        if (event.target.className == "tabs__item") {
            //let dataTab = event.target.getAttribute('data-tab');
            let currentDataTab = event.target.dataset.tab;
            //console.log(currentDataTab);
            for (let i = 0; i < tabsItem.length; i++) {
                tabsItem[i].classList.remove('active');
            }
            event.target.classList.add('active');
            for (let i = 0; i < tabsInfo.length; i++) {
                if (currentDataTab == i) {
                    tabsInfo[i].classList.add('active');
                } else {
                    tabsInfo[i].classList.remove('active');
                }
            }
        }
    }
}


// Counter ========================================================================================================================================================

if (document.querySelectorAll('.cart').length > 0) {
  function catalogItemCounter(field){

    var fieldCount = function(el) {

      var
        // Min. value
        min = el.data('min') || false,

        // Max. value
        max = el.data('max') || false,

        // Decrease button
        dec = el.prev('.dec'),

        // Increase Quantity Button
        inc = el.next('.inc');

      function init(el) {
        if(!el.attr('disabled')){
          dec.on('click', decrement);
          inc.on('click', increment);
        }

        // Decrease the value
        function decrement() {
          var value = parseInt(el[0].value);
          value--;

          if(!min || value >= min) {
            el[0].value = value;
          }
        };

        // Increase the value
        function increment() {
          var value = parseInt(el[0].value);

          value++;

          if(!max || value <= max) {
            el[0].value = value++;
          }
        };

      }

      el.each(function() {
        init($(this));
      });
    };

    $(field).each(function(){
      fieldCount($(this));
    });
  }

catalogItemCounter('.fieldCount');
}
